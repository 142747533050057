import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import EntityTablePDF from '../home/EntityTable/PDF';
import TerritoryTablePDF from '../home/Territories/PDF';
import VendorTablePDF from '../home/Vendor/PDF';
import PDFHeader from './PDFHeader';

export default (props) => {
    const { data: { images, tables, reportTitle, header }, widgetProps: { widgetState } } = props;
    const views = {
            "streams_and_listeners": <View style={styles.section}>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Units</Text>
                    <Image source={images.streams_and_listeners} />
                </View>
            </View>,
            "top_vendors": <View style={styles.columnSection}>      
                <Text style={styles.smallHeader}>Platforms</Text>
                {widgetState.vendors.mode != 'table' &&  <View style={styles.column}>
                  <Image source={images.home_vendors} style={styles.image} />
                </View>}
                {widgetState.vendors.mode == 'table' && <View style={styles.column}>
                  <VendorTablePDF data={tables.vendorsTable} />
                </View>}
            </View>,
            "top_territories": <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Territories</Text>
                {widgetState.territories.mode != 'line' && <View style={styles.column}>
                  <TerritoryTablePDF data={tables.territories} />
                </View>}
                {widgetState.territories.mode == 'line' && <View style={styles.column}>
                  <Image source={images.top_territories} style={styles.image} />
                </View>}
            </View>,
            "top_imprints": tables.imprints ? <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Top Imprints</Text>
                {widgetState.top_imprints.mode == 'table' && <View style={styles.column}>
                  <EntityTablePDF entity="imprints" data={tables.imprints} />
                </View>}
                {widgetState.top_imprints.mode != 'table' && <View style={styles.column}>
                  <Image source={images.top_imprints} style={styles.image} />
                </View>}
            </View> : null,
            
            "top_artists": <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Top Artists</Text>
                {widgetState.top_artists.mode == 'table' && <View style={styles.column}>
                  <EntityTablePDF entity="artists" data={tables.artists} />
                </View>}
                {widgetState.top_artists.mode != 'table' && <View style={styles.column}>
                  <Image source={images.top_artists} style={styles.image} />
                </View>}
            </View>,
            "top_products": <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Top Products</Text>
                {widgetState.top_products.mode == 'table' && <View style={styles.column}>
                  <EntityTablePDF entity="products" data={tables.products} />
                </View>}
                {widgetState.top_products.mode != 'table' && <View style={styles.column}>
                  <Image source={images.top_products} style={styles.image} />
                </View>}
          </View>,
          "top_tracks": <View style={styles.columnSection}>
              <Text style={styles.smallHeader}>Top Tracks</Text>
                {widgetState.top_tracks.mode == 'table' && <View style={styles.column}>
                  <EntityTablePDF entity="tracks" data={tables.tracks} />
                </View>}
                {widgetState.top_tracks.mode != 'table' && <View style={styles.column}>
                  <Image source={images.top_tracks} style={styles.image} />
                </View>}
          </View>,
    };
  return <Document>
    <Page size="A4" style={styles.page}>
        <PDFHeader header={header} />
        <View style={styles.section}>
            <OverviewPDF data={header.overview} />
        </View>    
        {Object.values(views)}
    </Page>
  </Document>
};