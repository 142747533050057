import React, { Component, useState } from 'react';
import Navigation from './Navigation';

var overviewSvg = require('!svg-inline-loader!../../../public/img/icons/overview.svg');
var catalogueSvg = require('!svg-inline-loader!../../../public/img/icons/catalogue.svg');
var imprintsSvg = require('!svg-inline-loader!../../../public/img/icons/imprints.svg');
var artistsSvg = require('!svg-inline-loader!../../../public/img/icons/artists.svg');
var productsSvg = require('!svg-inline-loader!../../../public/img/icons/products.svg');
var projectsSvg = require('!svg-inline-loader!../../../public/img/icons/projects.svg');
var tracksSvg = require('!svg-inline-loader!../../../public/img/icons/tracks.svg');
var analyticsSvg = require('!svg-inline-loader!../../../public/img/icons/analytics.svg');
var playlistLibrarySvg = require('!svg-inline-loader!../../../public/img/icons/playlist_library.svg');
var audienceAnalysisSvg = require('!svg-inline-loader!../../../public/img/icons/audience_analysis.svg');
var reportingSvg = require('!svg-inline-loader!../../../public/img/icons/reporting.svg');
var heartbeatsSvg = require('!svg-inline-loader!../../../public/img/icons/heartbeats.svg');
var nosedivesSvg = require('!svg-inline-loader!../../../public/img/icons/nosedives.svg');
var newReleasesSvg = require('!svg-inline-loader!../../../public/img/icons/new_releases.svg');
var trackComparisonSvg = require('!svg-inline-loader!../../../public/img/icons/track_comparison.svg');
var dataAdminSvg = require('!svg-inline-loader!../../../public/img/icons/data-admin.svg');
var spotifySvg = require('!svg-inline-loader!../../../public/img/icons/spotify.svg');
var monetizationSvg = require('!svg-inline-loader!../../../public/img/icons/monetization.svg');

class NavigationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [
        {
          label: "Overview",
          route: "/overview",
          icon: overviewSvg,
        },
        {
          label: "Catalog",
          route: "/catalog",
          isSubmenuOpened: false,
          icon: catalogueSvg,
          submenu: [
            {
              label: "Imprint",
              route: "/catalog/imprints",
              icon: imprintsSvg,
              isSubmenuOpened: false,
              counter: true
            },
            {
              label: "Artist",
              route: "/catalog/artists",
              icon: artistsSvg,
              isSubmenuOpened: false,
              counter: true
            },
            {
                label: "Project",
                route: "/catalog/projects",
                icon: projectsSvg,
                isSubmenuOpened: false,
                counter: true,
                siloCodes: ["con", "sec", "eon"]
            },

            {
              label: "Product",
              route: "/catalog/products",
              icon: productsSvg,
              isSubmenuOpened: false,
              counter: true
            },
            
            {
              label: "Track",
              route: "/catalog/tracks",
              icon: tracksSvg,
              isSubmenuOpened: false,
              counter: true
            },
          ]
        },
        // {
        //   label: "Analytics",
        //   route: "/playlists|campaigns|audience|territory-analysis|comparison/",
        //   isSubmenuOpened: false,
        //   icon: analyticsSvg,
        //   submenu: [
        //     {
        //       label: "Audience Analysis",
        //       route: "/audience",
        //       icon: audienceAnalysisSvg,
        //       isSubmenuOpened: false
        //     },
            
        //     /*
        //     {
        //       label: "Territories",
        //       route: "/territory-analysis",
        //       icon: territoriesSvg,
        //       isSubmenuOpened: false
        //     }
        //     */
        //   ]
        // },
        {
          label: "Playlist Library",
          route: "/playlists",
          icon: playlistLibrarySvg,
          // isSubmenuOpened: false
        },
        {
          label: "Comparison",
          route: "/comparison",
          icon: trackComparisonSvg,
          // isSubmenuOpened: false
        },
        {
          label: "Heartbeats",
          route: "/reporting/heartbeats",
          icon: heartbeatsSvg,
          // isSubmenuOpened: false,
          permission: "allow_heartbeats",
        },
        {
          label: "New Releases",
          route: "/reporting/new-releases",
          icon: newReleasesSvg,
          // isSubmenuOpened: false,
          permission: "allow_releases"
        },
        {
            label: "Nosedives",
            route: "/reporting/nosedives",
            icon: nosedivesSvg,
            // isSubmenuOpened: false,
            permission: "allow_heartbeats",
        }, 
        {
            label: "Spotify Discovery",
            route: "/reporting/spotify-discovery",
            icon: spotifySvg,
            permission: "allow_releases"
        }, 
        {
            label: "Monetization",
            route: "/reporting/monetization",
            icon: monetizationSvg,
            permission: "allow_releases"
        }, 
        {
            label: "Data Status",
            route: "/data-status",
            icon: dataAdminSvg,
            // isSubmenuOpened: false,

            permission: "allow_releases"
        }
        // {
        //   label: "Reporting",
        //   route: "/reporting/",
        //   isSubmenuOpened: false,
        //   icon: reportingSvg,
        //   permission: ["allow_heartbeats", "allow_releases"],
        //   submenu: [
            
        //   ]
        // },
        // {
        //   label: "User Preferences",
        //   route: "/user",
        //   isSubmenuOpened: false,
        //   icon: userPreferencesSvg,
        //   submenu: [
        //     {
        //       label: "Profile",
        //       route: "/user/profile",
        //       icon: profileSvg,
        //       isSubmenuOpened: false
        //     },
        //     {
        //         label: "Templates",
        //         route: "/user/templates",
        //         icon: tableSvg,
        //         isSubmenuOpened: false
        //       },              
        //     {
        //       label: "Favorites",
        //       route: "/user/favorites",
        //       icon: favoritesSvg,
        //       isSubmenuOpened: false
        //     },
        //     {
        //       label: "Notifications",
        //       route: "/user/notifications",
        //       icon: notificationsSvg,
        //       isSubmenuOpened: false,
        //       permission: "allow_preferences"
        //     },
        //     {
        //       label: "API",
        //       route: "/user/api",
        //       icon: notificationsSvg,
        //       isSubmenuOpened: false,
        //       permission: "allow_api"
        //     }
        //   ]
        // },
        // {
        //   label: "Admin",
        //   route: "/admin",
        //   isSubmenuOpened: false,
        //   icon: adminSvg,
        //   permission: ["allow_admin", "can_grant_access"],
        //   submenu: [
        //     {
        //       label: "Statistics",
        //       route: "/admin/statistics",
        //       icon: statisticsSvg,
        //       isSubmenuOpened: false,
        //       permission: "allow_admin"
        //     },
        //     {
        //       label: "White Label",
        //       route: "/admin/white-label",
        //       icon: whiteLabelSvg,
        //       isSubmenuOpened: false,
        //       permission: "allow_admin"
        //     },
        //     {
        //       label: "Data Admin",
        //       route: "/admin/data",
        //       icon: dataAdminSvg,
        //       isSubmenuOpened: false,
        //       permission: "allow_admin"
        //     },
        //     {
        //       label: "User Management",
        //       route: "/admin/users",
        //       icon: userManagementSvg,
        //       isSubmenuOpened: false,
        //       permission: "can_grant_access"
        //     }
        //   ]
        // },
      ],
    };
  }

  render () {
    const { menu } = this.state;
    return (
      <Navigation menuItems={menu} />
    )
  }
}

export default NavigationMain;