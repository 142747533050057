import { productsConstants } from '../constants/products';
import { productsService } from '../services/products';
import { productsFormatter } from '../formatters/products';
import { find } from 'lodash';

export const productsActions = {
    getProducts,
    getTopProducts,
    getProductsAutocomplete,
    getProductDetails,
    getCard,
    getStreamsStats,
    getProductsByBarcode,
    getTracks,
    getMetadata,
    getTimeseriesProducts,
    compareProducts,
    compareProductDemographics,
    compareProductTerritories,
    compareProductDatetime,
    compareProductVendors,
    getProductsReleaseDates,
    getOrderedTracks,   
    compareProductArtist
};

function getProducts(params, basic) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        productsService.getProducts(params, globalFilter, basic)
            .then(
                products => {
                    const data = productsFormatter.formatForTable(products);
                    //dispatch(success(data, products.pages));
                    return dispatch(getMetadata(data));                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_REQUEST } }
    function success(products, pages) { return { type: productsConstants.GET_PRODUCTS_SUCCESS, products, pages } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_FAILURE, error } }
}

function getProductsByBarcode(barcodes, format = false){
  return dispatch => {
    dispatch(request());
    if(!barcodes)
        return dispatch(success([]));

    return productsService.getProductsByBarcode(barcodes)
    .then(response => {
      const dataIsEmpty = response.data && response.data.length === 0;
      let result = dataIsEmpty ? [] : response.products;
	  if(format) {
		  result = productsFormatter.formatProductsByBarcode(result);
	  }
        dispatch(success(result));
		return result;
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request() { return { type: productsConstants.GET_PRODUCTS_BY_BARCODES_REQUEST } }
  function success(products) { return { type: productsConstants.GET_PRODUCTS_BY_BARCODES_SUCCESS, products } }
  function failure(error) { return { type: productsConstants.GET_PRODUCTS_BY_BARCODES_FAILURE, error } }
}


function getProductsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        productsService.getProductsAutocomplete(search)
            .then(
                products => {
                    const data = productsFormatter.formatAutocomplete(products.results, ['products']);
                    dispatch(success(data.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_AUTOCOMPLETE_REQUEST } }
    function success(products) { return { type: productsConstants.GET_PRODUCTS_AUTOCOMPLETE_SUCCESS, products} }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopProducts(params, useCache, filter) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        const fields = ['product_id', 'upc', 'curr_units', 'prev_units', 'units_diff', 'passive', 'active', 'skipped_audio_ratio', 'skipped_video_ratio', 'completed_audio_ratio', 'completed_video_ratio', 'curr_playlists', 'prev_playlists', 'playlists_diff', 'vendors', 'pre_add', 'free', 'discounted', 'premium'];

        if(filter) {
            let filtered = [];
//            if(filter.products){
//                filtered.push({
//                    id: 'products',
//                    value: filter.products                    
//                })
//            }
//            else {
                for(let entity of Object.keys(filter)) {
                    filtered.push({
                        id: entity,
                        value: filter[entity]
                    })
                };
//            }

            params.filtered = filtered;
        }
        
        productsService.getTopProducts(params, globalFilter, fields)
            .then(
                products => {
                    const data = productsFormatter.formatTop(products);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TOP_PRODUCTS_REQUEST } }
    function success(products) { return { type: productsConstants.GET_TOP_PRODUCTS_SUCCESS, products } }
    function failure(error) { return { type: productsConstants.GET_TOP_PRODUCTS_FAILURE, error } }
}


function getProductDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const products = getState().products.top,
            product = find(products, {product_id: id});
        return Promise.resolve(product)
            .then(
                product => {
                    const data = productsFormatter.formatDetails(product.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCT_DETAILS_REQUEST } }
    function success(id, product) { return { type: productsConstants.GET_PRODUCT_DETAILS_SUCCESS, id, product } }
    function failure(error) { return { type: productsConstants.GET_ARTIST_PRODUCT_FAILURE, error } }
}

function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return productsService.getCard(id)
            .then(
                card => {
                    card= productsFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_CARD_REQUEST } }
    function success(id, card) { return { type: productsConstants.GET_PRODUCTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_CARD_FAILURE, error } }
}

function getStreamsStats(ids, filtered = []) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        Promise.all([productsService.getStreamsStats(ids, filtered, globalFilter),
                     productsService.getMetadataByIDs(ids)])
            .then(
                ([products, metadata]) => {
                    //const metadata = getState().products.metadata;
                    products = productsFormatter.formatStreamsStatsByIDs(products, metadata.data);
                    dispatch(success(products));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(products) { return { type: productsConstants.GET_STREAMS_STATS_SUCCESS, products } }
    function failure(error) { return { type: productsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTracks(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        ids = ids.map(id=>Number(id));
        productsService.getTracks(ids)
            .then(
                tracks => {
                    const data = productsFormatter.formatTracks(tracks.data);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TRACKS_REQUEST } }
    function success(tracks) { return { type: productsConstants.GET_TRACKS_SUCCESS, tracks} }
    function failure(error) { return { type: productsConstants.GET_TRACKS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.upc);
        productsService.getMetadata(ids)
            .then(
                metadata => {
                    entities = productsFormatter.formatMetadata(entities, metadata.products);
                    dispatch(success(entities, metadata.products));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TOP_PRODUCTS_REQUEST } }
    function success(products, metadata) { return { type: productsConstants.GET_TOP_PRODUCTS_SUCCESS, products, metadata } }
    function failure(error) { return { type: productsConstants.GET_TOP_PRODUCTS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.upc);
        productsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = productsFormatter.formatMetadata(entities.data, metadata.products);
                    dispatch(success(data, entities.total, metadata.products));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_REQUEST } }
    function success(products, total, metadata) { return { type: productsConstants.GET_PRODUCTS_SUCCESS, products, total, metadata } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_FAILURE, error } }

}

function getMetadataByIDs(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.upc);
        productsService.getMetadataByIDs(ids)
            .then(
                metadata => {
                    const data = productsFormatter.formatMetadataByIDs(entities.data, metadata.products);
                    dispatch(success(data, entities.total, metadata.products));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_REQUEST } }
    function success(products, total, metadata) { return { type: productsConstants.GET_PRODUCTS_SUCCESS, products, total, metadata } }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_FAILURE, error } }

}



function getTimeseriesProducts(params, useCache, filter) {
    return ( dispatch, getState ) => {
        if(getState().products.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;

        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }

        return productsService.getTimeseriesProducts(params, globalFilter)
            .then(
                products => {
                    const metadata = getState().products.metadata;
                    const data = productsFormatter.formatStreamsStats(products, metadata);
                    dispatch(success(data));
                    return products;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_TIMESERIES_PRODUCTS_REQUEST } }
    function success(products) { return { type: productsConstants.GET_TIMESERIES_PRODUCTS_SUCCESS, products } }
    function failure(error) { return { type: productsConstants.GET_TIMESERIES_PRODUCTS_FAILURE, error } }
}

function compareProducts(ids, weeks, releaseDates, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        productsService.compareProducts(ids, weeks, releaseDates, territories, vendors, currentUser)
            .then(
                products => {
                    const data = productsFormatter.formatCompareProducts(ids, products);
                    return dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.COMPARE_PRODUCTS_REQUEST } }
    function success(products) { return { type: productsConstants.COMPARE_PRODUCTS_SUCCESS, products} }
    function failure(error) { return { type: productsConstants.COMPARE_PRODUCTS_FAILURE, error } }
}

function compareProductDemographics(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        productsService.compareProductDemographics(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                product => {
                    const data = productsFormatter.formatCompareProductDemographics(product);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.COMPARE_PRODUCT_DEMOGRAPHICS_REQUEST } }
    function success(id, product) { return { type: productsConstants.COMPARE_PRODUCT_DEMOGRAPHICS_SUCCESS, id, product} }
    function failure(error) { return { type: productsConstants.COMPARE_PRODUCT_DEMOGRAPHICS_FAILURE, error } }
}
function compareProductArtist(id, artistID, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        productsService.compareProductArtist(id, artistID, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                streams => {
                    const data = productsFormatter.formatCompareProductArtist(streams);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.COMPARE_PRODUCT_ARTIST_REQUEST } }
    function success(id, product) { return { type: productsConstants.COMPARE_PRODUCT_ARTIST_SUCCESS, id, product} }
    function failure(error) { return { type: productsConstants.COMPARE_PRODUCT_ARTIST_FAILURE, error } }
}


function compareProductTerritories(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        productsService.compareProductTerritories(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                product => {
                    const data = productsFormatter.formatCompareProductTerritories(product);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.COMPARE_PRODUCT_TERRITORIES_REQUEST } }
    function success(id, product) { return { type: productsConstants.COMPARE_PRODUCT_TERRITORIES_SUCCESS, id, product} }
    function failure(error) { return { type: productsConstants.COMPARE_PRODUCT_TERRITORIES_FAILURE, error } }
}

function compareProductDatetime(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        productsService.compareProductDatetime(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                product => {
                    const data = productsFormatter.formatCompareProductDatetime(product);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.COMPARE_PRODUCT_DATETIME_REQUEST } }
    function success(id, product) { return { type: productsConstants.COMPARE_PRODUCT_DATETIME_SUCCESS, id, product} }
    function failure(error) { return { type: productsConstants.COMPARE_PRODUCT_DATETIME_FAILURE, error } }
}

function compareProductVendors(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        productsService.compareProductVendors(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                product => {
                    const data = productsFormatter.formatCompareProductVendors(product);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.COMPARE_PRODUCT_VENDORS_REQUEST } }
    function success(id, product) { return { type: productsConstants.COMPARE_PRODUCT_VENDORS_SUCCESS, id, product} }
    function failure(error) { return { type: productsConstants.COMPARE_PRODUCT_VENDORS_FAILURE, error } }
}

function getProductsReleaseDates(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const idsArray = ids.split(','); 
        return productsService.getMetadata(idsArray, false)
            .then(
                products => {
                    products = productsFormatter.formatProductsReleaseDates(products, ids);
                    dispatch(success(products));
                    return products;
                },
                error => {
                    dispatch(failure('error'))
                }
            )
    };

    function request() { return { type: productsConstants.GET_PRODUCTS_RELEASE_DATES_REQUEST } }
    function success(products) { return { type: productsConstants.GET_PRODUCTS_RELEASE_DATES_SUCCESS, products} }
    function failure(error) { return { type: productsConstants.GET_PRODUCTS_RELEASE_DATES_FAILURE, error } }
}

function getOrderedTracks(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return productsService.getOrderedTracks(id)
            .then(
                tracks => {
                    let productData = productsFormatter.formatOrderedTracks(tracks);
                    dispatch(success(id, productData.tracks));
                    return productData;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: productsConstants.GET_ORDERED_TRACKS_REQUEST } }
    function success(id, tracks) { return { type: productsConstants.GET_ORDERED_TRACKS_SUCCESS, id, tracks } }
    function failure(error) { return { type: productsConstants.GET_ORDERED_TRACKS_FAILURE, error } }
}