import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, uniq, map } from 'lodash';
import { Link } from 'react-router-dom';
import Tooltip from '../../common/Tooltip';

class MissingTiktokWarning extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			supportedSilos: ['xyz', 'sec', 'str', 'scy', 'exc', 'eon', 'res']
		};
	}


	render (){
		const {user: {primary_silo_code}} = this.props.user;
		const showWarning = !this.state.supportedSilos.includes(primary_silo_code)
		if(showWarning){
			return <div className="missing-data-warning-holder">
				<div className="missing-data-warning">
					<strong>Note: </strong>
					From Oct 31st 2024 TikTok data is currently unavailable from source. We are working hard with your data teams to secure this data.
				</div>
			</div>
		}
		return null;            
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	} 
}

export default connect(mapStateToProps)(MissingTiktokWarning)