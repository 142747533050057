import { map, uniq, capitalize, sumBy, find, orderBy, sum } from 'lodash'
import moment from 'moment';
import alpha from 'color-alpha';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import {percentageValue} from '../../helpers/NumberFormatter';

import { formatChartLabelItem } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat, dateRangeSourceFlat, sortDateRange} from '../../helpers/DateRange';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';

export const audienceFormatter = {
    formatStreamsStats,
    formatDemographicsStats,
    formatTerritoryStats,
    formatDemographicsTerritoryStats,
    formatSourceStats,
    formatSourceTimeseries,
    formatSourcePlaylists,
    formatDeviceStats,
    formatPlaylistStats,
    formatRepeatListeners,
    formatRepeatListenersTimeseries,
    formatRepeatListenersRatio,
    formatTopPlaylistStats,
    formatTopPlaylistStatsMeta
};

function formatStreamsStats(data) {
    let labels = [],
    datasets = [],
    contentTypes = [];

    if(data) {
        contentTypes = uniq(map(data, 'cont_typ'));
        const {dates, period} = dateRangeFlat(data, 'period_date');
        
        let totalUnitsDataset = [],
            totalListenersDataset = [];
        
        for(let contentType of contentTypes) {
            /*
            datasets.push({
                label: 'Total Units',
                total: 0,
                data: [],
                contentType,
                fill: false,
                borderColor: colors[1],
                backgroundColor: colors[1],
                pointBorderColor: colors[1],
                pointBackgroundColor: colors[1],                        
            })
            */
            datasets.push({
                label: 'Unique Listeners',
                total: 0,
                data: [],
                contentType,
                fill: false,
                borderColor: colors[0],
                backgroundColor: colors[0],
                pointBorderColor: colors[0],
                pointBackgroundColor: colors[0],                        
            });           
        }
        
        for(let date of dates) {
            let totalUnits = 0,
                totalListeners = 0;
            for(let contentType of contentTypes) {
                const day = find(data, item => (item.cont_typ == contentType && item.period_date == date));
//                const units = day ? day.total_streams : null;
                const listeners = day ? day.listeners : null;
//                const unitsDataset = find(datasets, dataset => (dataset.contentType == contentType && dataset.label == 'Total Units'));
                const listenersDataset = find(datasets, dataset => (dataset.contentType == contentType && dataset.label == 'Unique Listeners'));
//                unitsDataset.data.push(units);
                listenersDataset.data.push(listeners);
//                unitsDataset.total += Number(units);
                listenersDataset.total += Number(listeners);
//                totalUnits += Number(units);
                totalListeners += Number(listeners);
            }
//            totalUnitsDataset.push(totalUnits);
            totalListenersDataset.push(totalListeners);
        }
        
        /*
        datasets.push({
            label: 'Total Units',
            total: 0,
            data: totalUnitsDataset,
            contentType: 0,
            fill: false,
            borderColor: colors[1],
            backgroundColor: colors[1],
            pointBorderColor: colors[1],
            pointBackgroundColor: colors[1],                        
        })
        */
        datasets.push({
            label: 'Unique Listeners',
            total: 0,
            data: totalListenersDataset,
            contentType: 0,
            fill: false,
            borderColor: colors[0],
            backgroundColor: colors[0],
            pointBorderColor: colors[0],
            pointBackgroundColor: colors[0],                        
        });           
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets, contentTypes };

}

function formatDemographicsStats(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    const colors = ["#1E90FF", "#FB339B", "#F59E00"];
    
    for(let item of data) {
        if(item.age === null)
            item.age = 'Unknown';
        if(item.gender === null)
            item.gender = 'Unknown';        
    }
        
    let datasets = {},
        table = [],
        active = {},
        passive = {},        
        genders = uniq(map(data, 'gender')),        
        ages = uniq(map(data, 'age'));
    
    ages.sort();
    
    for(let gender of genders) {
        datasets[`${gender}_active`] = [];
        datasets[`${gender}_passive`] = [];
        active[gender] = [];
        passive[gender] = [];
    }

    for(let entry of data) {
        //datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        active[entry.gender][ages.indexOf(entry.age)] = entry.active;
        passive[entry.gender][ages.indexOf(entry.age)] = entry.passive;
        datasets[`${entry.gender}_active`][ages.indexOf(entry.age)] = entry.active; 
        datasets[`${entry.gender}_passive`][ages.indexOf(entry.age)] = entry.passive;
    }

    datasets = map(datasets, (data, label)=>{
        const [gender, mode] = label.split('_');
        const colors = ["#1E90FF", "#FB339B", "#F59E00"];
        const color = colors[genderOrder.indexOf(gender)];
        const barColor = mode == 'passive' ? alpha(color, 0.5): color; 
        
        return {
            data, 
            label: `${gender} ${mode == 'passive' ? '(passive)' : ''}`, 
            stack: gender, 
            active: active[gender], 
            passive: passive[gender],
            backgroundColor: barColor
        };
    });

    
    for(let age of ages) {
        let row = {};
        for(let gender of genderOrder) {
            const cell = find(data, item=>(item.age == age && item.gender == gender));
            row[gender.toLowerCase()] = cell ? cell.curr_units : 0;
        }
        row.age = age;
        table.push(row);
    };
    /*
    for(let gender of genderOrder) {
        let row = [];
        
        for(let age of ages) {
            const cell = find(data, item=>(item.age == age && item.gender == gender));
            row.push(cell ? cell.listeners : 0);
        };
        
        barDataset.push({
            label: gender,
            data: row,
            backgroundColor: colors[genderOrder.indexOf(gender)]
        });
    }
    */
    let mainDataset = {data: [], label: "units", backgroundColor: []},
        splitDataset = {data: [], label: "plays" , backgroundColor: []},
    
    pieLabels = [];

    const pieGrandTotal = sum(data.map(dataset=>dataset.curr_units));
    
    for(let gender of genderOrder) {
        const row = data.filter(item=>(item.gender == gender));
        const active = sumBy(row, 'active');
        const passive = sumBy(row, 'passive');
        const color = colors[genderOrder.indexOf(gender)];
        const total = Number(active)+Number(passive);
    
        splitDataset.data.push(Number(active));
        splitDataset.data.push(Number(passive));
        splitDataset.data.push(0);
        
        splitDataset.backgroundColor.push(alpha(color, 1));
        splitDataset.backgroundColor.push(alpha(color, 0.5));
        splitDataset.backgroundColor.push(alpha(color, 1));
    
        mainDataset.data.push(0);
        mainDataset.data.push(0);
        mainDataset.data.push(total);
        
        mainDataset.backgroundColor.push(color);
        mainDataset.backgroundColor.push(color);
        mainDataset.backgroundColor.push(color);
        
        pieLabels.push(`${gender} (active) ${Math.round(active/total*100)}%`);
        pieLabels.push(`${gender} (passive) ${Math.round(passive/total*100)}%`);
        pieLabels.push(`${gender} ${Math.round(total/pieGrandTotal*100)}%`);
    }
    
    
    return { table, chart: {labels: pieLabels, datasets: [splitDataset, mainDataset]} , barChart: {labels: ages, datasets}};
}

function formatTerritoryStats(data, limit = 10) {
    if(!data)
        return {};
        
    let world = {},
        table;
    
    const total = sumBy(data, 'listeners');
    
    table = map(data, (territory=>{        
        const code = territory.territory,
            country = find(countriesByAbbreviation, {abbreviation: code});
        
        if(territory.territory == 'ZZ')
            territory.territory_name = 'Unknown';
        
        territory.name = country ? country.country : '-';
        territory.code = code;
        territory.value = territory.listeners;
        territory.share = Math.round(territory.total_streams/territory.all_territories_listeners*10000)/100;
        return territory;
    }));
    table = orderBy(table, ['value'], ['desc']);
    
    for (let rank = 0; rank < table.length; rank++) {
        let item = table[rank];
        item.rank = rank + 1; 
    }
    
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    }                
    
    return {world, table};
}

function formatDemographicsTerritoryStats(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    const colors = ["#4D77FF", "#FC66B4", "#FDC14E"];
    
    for(let item of data) {
        if(item.age === null)
            item.age = 'Unknown';
        if(item.gender === null)
            item.gender = 'Unknown';        
    }
        
    let datasets = {},
        table = [],
        active = {},
        passive = {},        
        genders = uniq(map(data, 'gender')),        
        ages = uniq(map(data, 'age'));
    
    ages.sort();
    
    for(let gender of genders) {
        datasets[`${gender}_active`] = [];
        datasets[`${gender}_passive`] = [];
        active[gender] = [];
        passive[gender] = [];
    }

    for(let entry of data) {
        //datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        active[entry.gender][ages.indexOf(entry.age)] = entry.active;
        passive[entry.gender][ages.indexOf(entry.age)] = entry.passive;
        datasets[`${entry.gender}_active`][ages.indexOf(entry.age)] = entry.active; 
        datasets[`${entry.gender}_passive`][ages.indexOf(entry.age)] = entry.passive;
    }

    datasets = map(datasets, (data, label)=>{
        const [gender, mode] = label.split('_');
        const colors = ["#4D77FF", "#FC66B4", "#FDC14E"];
        const color = colors[genderOrder.indexOf(gender)];
        const barColor = mode == 'passive' ? alpha(color, 0.5): color; 
        
        return {
            data, 
            label: `${gender} ${mode == 'passive' ? '(passive)' : ''}`, 
            stack: gender, 
            active: active[gender], 
            passive: passive[gender],
            backgroundColor: barColor
        };
    });

    
    for(let age of ages) {
        let row = {};
        for(let gender of genderOrder) {
            const cell = find(data, item=>(item.age == age && item.gender == gender));
            row[gender.toLowerCase()] = cell ? cell.listeners : 0;
        }
        row.age = age;
        table.push(row);
    };

    return {labels: ages, datasets};
}

function formatSourceStats(data) {
    let labels = [],
    dataset = [],
    total = 0;

    for(let entry of data) {
        labels.push(entry.source);
        dataset.push(entry.listeners);
        total += entry.listeners;
    }
    
    
    data = data.map(entry=>{
        entry.share = total ? Math.round((entry.listeners/total)*10000)/100 : 0;
        return entry;
    });
    
    return {chart: {labels, datasets: [{ data:dataset, label: "Listeners"}]}, table: data};
}

function formatDeviceStats(data) {
    let labels = [],
    dataset = [],
    total = 0;
    
    data = data.filter(item=>item.device);

    for(let entry of data) {
        entry.device = String(entry.device);
        labels.push(entry.device);
        dataset.push(entry.listeners);
        total += entry.listeners;
    }
    
    
    data = data.map(entry=>{
        entry.share = total ? Math.round((entry.listeners/total)*10000)/100 : 0;
        return entry;
    });
    
    return {chart: {labels, datasets: [{ data:dataset, label: "Listeners"}]}, table: data};
}


function formatPlaylistStats(data) {
    for(let index in data) {
        let item = data[index];
        item.vendor = 'Spotify';
        item.rank = Number(index) + 1;
        item.ratio = Number(item.total_streams / item.listeners).toFixed(1);
    }
    return data;
}


function formatRepeatListeners(listens) {
    let thresholds = {11: [1001, 501, 101, 11], 6: [9, 8, 7, 6], 2: [5, 4, 3, 2], 1: [0, 0, 0, 1]},
        labels = {11: ['1001+', '501 - 1000', '101 - 500', '11 - 100'], 6: ['9-10', '8', '7', '6'], 2: ['5', '4', '3', '2'], 1: [0, 0, 0, '1']},
        dataset = {11: [0, 0, 0, 0], 6: [0, 0, 0, 0], 2:[0, 0, 0, 0] , 1:[0, 0, 0, 0]},
        colorsArray = colors.slice(0, 16).reverse(),
        totals = {11: 0, 6: 0, 2: 0, 1: 0};

    for(let listen of listens) {
        for (let threshold of Object.keys(thresholds).reverse()) {
            if(listen.listens >= threshold) {
                totals[threshold] += listen.listeners;
                for(let innerKey in thresholds[threshold]) {
                    let inner = thresholds[threshold][innerKey];
                    if(inner && listen.listens >= inner) {
                        listen.group = labels[threshold][innerKey];
                        dataset[threshold][innerKey] += listen.listeners;
                        break;
                    }
                }
                break;
            }
        }
    }
    
    
    let result = [],
        datasetArray = Object.values(dataset),
        thresholdArray = Object.values(labels);
    for(let resultIndex = 0; resultIndex < 4; resultIndex++) {
        let data = [],
            labels = [];
        for(let itemIndex in Object.keys(dataset)) {
            let item = datasetArray[itemIndex];
            data.push(item[resultIndex]);
            let labelItem = thresholdArray[itemIndex];
            labels.push(labelItem[resultIndex]);
        }
        result.push({
            data: data.reverse(),
            label: Object.keys(thresholds)[resultIndex], //String(resultIndex),
            labels: labels.reverse(),
            borderColor: colorsArray.slice(4*resultIndex, 4+(4*resultIndex)),
            backgroundColor: colorsArray.slice(4*resultIndex, 4+(4*resultIndex)),
        });
    }
    
    const grandTotal = sum(Object.values(totals));

    totals = Object.values(totals).map(total => ( grandTotal ? Math.round(total / grandTotal * 100) : 0 ) );
    
    result.reverse();
    
    totals.reverse();
    
    result.push({
        label: 'Total',
        data: [0, 0, 0, 0], 
        datalabels: {
            align: 'end',
            anchor: 'end',
            display: true,
            offset: 7,
            formatter: (value, context) => {return `${totals[context.dataIndex] } %`;}
        }

    })
        
    return {dataset: result, table: listens};
}

function formatRepeatListenersTimeseries(listens) {
    let datasets = [];
    const weeks = uniq(map(listens, 'week'));
    for(let weekIndex in weeks) {
        const week = weeks[weekIndex];
        const weekLabel = weekIndex > 0 ? `(${weekIndex})` : '';
        for(let engagement of ['Active', 'Passive']) {
            let dataset = [];
            const negative = engagement == 'Active';
            const colorIndex = negative ? 1 : 0;
            
            for(let listen of listens.filter(l=>(l.week == week && l.engagement == engagement))) {
                const shift = (Math.random()/4);
                dataset.push({listeners: listen.listeners, engagement: listen.engagement, x: listen.listens, y: negative ? listen.week-shift : listen.week + shift})
            }
            datasets.push({
                label: `${engagement} ${weekLabel}`, 
                data: dataset, 
                borderColor: colors[colorIndex],
                backgroundColor: colors[colorIndex],
                pointBorderColor: colors[colorIndex],
                pointBackgroundColor: colors[colorIndex],                
            });
        }
    }
    return { labels: ['Listens'], datasets, table: listens }
}

function formatRepeatListenersRatio(streams) {
    let datasets = {Active: [], Passive: []};
    const {dates, period} = dateRangeFlat(streams, 'stream_date');
    for(let date of dates) {
        for(let engagement of Object.keys(datasets)) {
            const row = find(streams, stream => (stream.stream_date == date && stream.engagement == engagement));
            datasets[engagement].push(row ? row.ratio : null);
        }
    }
    
    let result = [];
    for(let engagement of Object.keys(datasets)) {
        result.push({label: engagement, data: datasets[engagement]})
    }
    const labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return { labels, datasets: result }
}

function formatTopPlaylistStats(data) {
    for(let index in data) {
        let item = data[index];
        item.vendor = 'Spotify';
        item.rank = Number(index) + 1;
        item.ratio = Number(item.total_streams / item.listeners).toFixed(1);
    }
    return data;
}

function formatTopPlaylistStatsMeta(data, metadata) {
    for(let index in data) {
        let item = data[index];
        const metaItem = find(metadata.playlists, (meta)=>meta.id == item.playlist_id);
        item.rank = Number(index) + 1;
        item.ratio = Number(item.total_streams / item.listeners).toFixed(1);
        if(metaItem) {
            item.playlist_name = metaItem.short_name;
            item.vendor = metaItem.vendor;    
        }
        else {
            item.playlist_name = 'tbd';
            item.vendor = 'Spotify';    
        }
        
    }
    return data;
}


function formatSourceTimeseries(data) {
    let labels = [],
        dataset = [],
        sources = uniq(map(data, 'source')),
        timelineLabels = [],
        timelineDatasets = [],
        total = 0;
    
    sources.sort();
    for(let source of sources) {
        timelineDatasets[source] = [];
    }

    const { dates, period } = dateRangeSourceFlat(data);

    timelineDatasets = sources.map(source => {
        let dataNew = [];
        
        for(let date of dates) {
            let sourceNew = find(data, {period_date: date, source: source});
            
            dataNew.push(sourceNew ? sourceNew.listeners : null);
        }     
        
        return {
            label: source,
            data: dataNew,
            fill: false
        }
    });    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels: timelineLabels, datasets: timelineDatasets};
}

function formatSourcePlaylists(data) {
    let playlistData = [],
        radioData = [],
        playlistLabels = [],
        radioLabels = [],
        playlistColors = [],
        radioColors = [];
        
    for(let itemIndex in data) {
        const item = data[itemIndex];
        if(item.source == 'Playlist') {
            playlistData.push(item.total_streams);
            playlistLabels.push(item.playlist_type);
            playlistColors.push(colors[itemIndex]);
        }
        else if(item.source == 'Radio') {
            radioData.push(item.total_streams);
            radioLabels.push(item.playlist_type);
            radioColors.push(colors[itemIndex]);
        }
    }

    return {
        playlist: {
            labels: playlistLabels,
            datasets: [{
                label: 'Streams',
                data: playlistData,
                backgroundColor: playlistColors
            }]
        },
        radio: {
            labels: radioLabels,
            datasets: [{
                label: 'Streams',
                data: radioData,
                backgroundColor: radioColors
            }]
        }
    }
}