import React, { Component } from 'react';
import { get, toLower } from 'lodash';
import Report from './Report';
// import TabContainer from '../../components/common/Grid/TabContainer';

class Platforms extends Component {
	platforms = [
		{label: 'Spotify', value: 'spotify'},
		{label: 'Amazon', value: 'amazon'}
	]
	constructor(props) {
		super(props);
		const platform = get(props, 'match.params.platform', this.platforms[0].value);
		this.state = {
			platform
		}
		this.setPlatform = this.setPlatform.bind(this);
	}
	
	setPlatform(platform){
		this.setState({
			platform: null
		}, ()=>{
			this.setState({
				platform: platform.value
			});	
		});
	}

	render() {
		const currentPlatform = this.state.platform;
		const {sharedMode} = this.props;
		return <div className="inner-page result-page">
			<div className="tabs-content">
				<div className="tab-container">
				{this.platforms.map(platform=><span onClick={()=>this.setPlatform(platform)} className={`tab ${currentPlatform == platform.value ? 'active' : ''}`}><span className={`tab-icon tab-icon--${platform.label.toLowerCase()}`}></span>{platform.label}</span>)}</div>
				<div className="tab-content">
					{currentPlatform && <Report platform={currentPlatform} sharedMode={sharedMode} />}
				</div>
			</div>
		</div>
	}
}

export default Platforms;