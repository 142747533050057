import { uniq, map, find } from 'lodash';
import { statsConstants } from '../constants/stats';
import { statsService } from '../services/stats';
import { imprintsService } from '../services/imprints';
import { artistsService } from '../services/artists';
import { productsService } from '../services/products';
import { projectsService } from '../services/projects';
import { tracksService } from '../services/tracks';
import { imprintsFormatter } from '../formatters/imprints';
import { artistsFormatter } from '../formatters/artists';
import { productsFormatter } from '../formatters/products';
import { projectsFormatter } from '../formatters/projects';
import { tracksFormatter } from '../formatters/tracks';
import { statsFormatter } from '../formatters/stats';

export const statsActions = {
    getTopStats,
    getCatalogStats,
    getVendorStats,
    getVendorTimeseries,
    getTopTerritoryStats,
    getTopTerritoryTimeseries,
    getTerritoryStats,
    getPlaylistTerritoryStats,
    getTerritoryTimeseries,    
    getDemographicsStats,
    getDemographicsTimeseries,
    getDevicesStats,
    getDevicesTimeseries,
    getSourcesStats,
    getSourcesDiscoveryStats,
    getSourcesTimeseries,
    getSourcesDiscoveryTimeseries,
    getMultiEntitiesStreams,
    getMultiEntitiesMetadata,
    getMultiMetadata,
    resetMinDate,
    getLocationStats,
    getTopLocationsStats
};

function getTopStats(useCache, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        
        let listeners = false,
            artistIDs = [];
        
        if(filtered !== undefined && filtered.length){
            for(let filter of filtered) {
                /*
                if(filter.id == 'imprints') {
                    listeners = false;
                    break;
                }
                */
                if(filter.id == 'artists') {
                    artistIDs = filter.value;
                }
            }
        }
        else {
            listeners = false;
        }
        // const metadataCall = artistIDs.length ? artistsService.getMetadata(artistIDs, true)
        //         .then(metadata=>{
        //             return metadata.data.reduce((total, current)=>total.concat(map(current.tracked_channels, channel=>channel.id)), []);
        //         })
        //         .then(channels=>artistsService.getFollowers(channels, globalFilter))
        //         : Promise.resolve({data: []});
        Promise.all([statsService.getTopStats(globalFilter, {filtered}, listeners), Promise.resolve({data: []})])
            .then(
                ([stats, channels]) => {
                    const data = statsFormatter.formatTop(stats, channels);
                    dispatch(success(data));
                },
                error => {
                    console.log(error);
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_STATS_REQUEST } }
    function success(stats) { return { type: statsConstants.GET_TOP_STATS_SUCCESS, stats } }
    function failure(error) { return { type: statsConstants.GET_TOP_STATS_FAILURE, error } }
}

function getCatalogStats(useCache) {
    return (dispatch, getState) => {
        dispatch(request());
        
        const stats = useCache ? getState().stats.catalog : undefined;
        if(stats!==undefined) {
            dispatch(success(stats));
            return;
        }
        const globalFilter = getState().filter.global;
        statsService.getCatalogStats(globalFilter)
            .then(
                stats => {
                    const data = statsFormatter.formatCatalogStats(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_CATALOG_STATS_REQUEST } }
    function success(stats) { return { type: statsConstants.GET_CATALOG_STATS_SUCCESS, stats } }
    function failure(error) { return { type: statsConstants.GET_CATALOG_STATS_FAILURE, error } }
}

function getVendorOptions(sort, dateGroup, parentEntity, parentEntityID, filter) {
    let options = {sorted: sort};
    if(parentEntity && parentEntityID) {
        options.filtered = [{
            id: parentEntity,
            value: parentEntityID
        }]
    };
    if(filter) {
        let filtered = [];
        for(let entity of Object.keys(filter)) {
            filtered.push({
                id: entity,
                value: filter[entity]
            })
        };
        options.filtered = filtered;
    }
    if(dateGroup) {
        options.dateGroup = dateGroup;
    }
    return options;    
}

function getVendorStats({mode, sort, vendor, contentType, dateGroup}, useCache, parentEntity, parentEntityID, filter) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const fields = ['content_type', 'vendor', 'curr_units', 'prev_units', 'pre_add', 'curr_playlist_units', 'active'];

        const options = getVendorOptions(sort, dateGroup, parentEntity, parentEntityID, filter);
        let globalFilter = getState().filter.global,
            cache = useCache ? getState().stats.vendorStatsCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getVendorStats(options, globalFilter, fields); 
            getData.then(
                stats => {
                    const data = statsFormatter.formatVendorStats(stats, mode, vendor, contentType);
                    dispatch(success(data, stats));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_VENDOR_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_VENDOR_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_VENDOR_STATS_FAILURE, error } }
}

function getVendorTimeseries({mode, sort, vendor, contentType, dateGroup}, useCache, parentEntity, parentEntityID, filter) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const options = getVendorOptions(sort, dateGroup, parentEntity, parentEntityID, filter);
        let globalFilter = getState().filter.global,
            cache = useCache ? getState().stats.vendorTimeseriesCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getVendorTimeseries(options, globalFilter); 
            return getData.then(
                stats => {
                    const data = statsFormatter.formatVendorTimeseries(stats, mode, vendor, contentType);
                    dispatch(success(data, stats));
                    return stats;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_VENDOR_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_VENDOR_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_VENDOR_TIMESERIES_FAILURE, error } }
}

function getTopTerritoryStats({mode, sort, limit}, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const fields = ['territory', 'curr_units', 'prev_units', 'curr_playlists', 'prev_playlists', 'active'];
        let globalFilter = getState().filter.global,

            cache = useCache ? getState().stats.topTerritoryCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getTopTerritoryStats({sorted: sort, limit}, globalFilter, fields);
            return getData
            .then(
                stats => {
                    const data = statsFormatter.formatTopTerritory(stats, mode);
                    dispatch(success(data, stats));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_TERRITORY_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_TOP_TERRITORY_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_TOP_TERRITORY_STATS_FAILURE, error } }
}

function getTopTerritoryTimeseries(ids, limit, useCache, dateGroup) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global,
            cache = false, // useCache ? getState().stats.topTerritoryCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getTopTerritoryTimeseries(ids, limit, globalFilter, dateGroup);
            return getData
            .then(
                stats => {
                    const data = statsFormatter.formatTopTerritoryTimeseries(stats);
                    dispatch(success(data, stats));
                    return stats;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_TERRITORY_TIMESERIES_REQUEST } }
    function success(timeseries, cache) { return { type: statsConstants.GET_TOP_TERRITORY_TIMESERIES_SUCCESS, timeseries, cache } }
    function failure(error) { return { type: statsConstants.GET_TOP_TERRITORY_TIMESERIES_FAILURE, error } }
}


function getTerritoryStats(filtered, sorted, limit) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        const params = {filtered, sorted, limit};
        const dataCall = statsService.getTerritoryStats;
        const fields = ['territory', 'per_cap_units', 'curr_units', 'prev_units', 'curr_playlists', 'prev_playlists', 'skipped_audio_ratio','skipped_video_ratio','completed_audio_ratio','completed_video_ratio','total_units', 'active'];
        return dataCall(params, globalFilter, fields) 
            .then(
                stats => {
                    const data = statsFormatter.formatTopTerritory(stats);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TERRITORY_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_TERRITORY_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_TERRITORY_STATS_FAILURE, error } }
}

function getPlaylistTerritoryStats(filtered, sorted, limit) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        const params = {filtered, sorted, limit};
        const dataCall = statsService.getTerritoryInPlaylistStats;
        const fields = ['territory', 'per_cap_units', 'curr_units', 'prev_units', 'curr_playlists', 'prev_playlists', 'skipped_audio_ratio','skipped_video_ratio','completed_audio_ratio','completed_video_ratio','total_units', 'active'];
        return dataCall(params, globalFilter, fields) 
            .then(
                stats => {
                    const data = statsFormatter.formatTopTerritory(stats);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TERRITORY_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_TERRITORY_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_TERRITORY_STATS_FAILURE, error } }
}


function getTerritoryTimeseries(entity, filtered, sorted, limit, dateGroup) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        const params = {filtered, sorted, limit, dateGroup};
        const dataCall = entity == 'playlists' ? statsService.getTerritoryInPlaylistTimeseries : statsService.getTerritoryTimeseries;
        return dataCall(params, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatTerritoryTimeseries(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TERRITORY_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_TERRITORY_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_TERRITORY_TIMESERIES_FAILURE, error } }
}



function getDemographicsStats(entity, id, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        return statsService.getDemographicsStats({[entity]:id}, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatDemographics(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEMOGRAPHICS_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEMOGRAPHICS_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEMOGRAPHICS_STATS_FAILURE, error } }
}

function getDemographicsTimeseries(entity, id, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        return statsService.getDemographicsTimeseries({[entity]:id}, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatDemographicsTimeseries(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEMOGRAPHICS_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEMOGRAPHICS_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEMOGRAPHICS_TIMESERIES_FAILURE, error } }
}


function getDevicesStats(entity, id, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        if(!filtered) {
            filtered = {[entity]:id};
        }
        let globalFilter = getState().filter.global;
        const fields = ['device', 'curr_units', 'prev_units'];
        return statsService.getDevicesStats(filtered, globalFilter, fields) 
            .then(
                stats => {
                    const data = statsFormatter.formatDevices(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEVICES_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEVICES_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEVICES_STATS_FAILURE, error } }
}

function getDevicesTimeseries(entity, id, useCache = false, filtered = null) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        if(!filtered) {
            filtered = {[entity]:id};
        }

        const cache = useCache ? getState().stats.devicesTimeseriesCache : null;
        const getData = cache ? Promise.resolve(cache) : statsService.getDevicesTimeseries(filtered, globalFilter);

        
        return  getData
            .then(
                stats => {
                    const data = statsFormatter.formatDevicesTimeseries(stats);
                    dispatch(success(data, stats));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEVICES_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEVICES_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEVICES_TIMESERIES_FAILURE, error } }
}


function getSourcesStats(entity, id, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        if(!filtered) {
            filtered = {[entity]:id};
        }
        let globalFilter = getState().filter.global;
        const fields = ['source', 'curr_units', 'prev_units'];
        return statsService.getSourcesStats(filtered, globalFilter, fields) 
            .then(
                stats => {
                    const data = statsFormatter.formatSources(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_SOURCES_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_SOURCES_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_SOURCES_STATS_FAILURE, error } }
}

function getSourcesDiscoveryStats(entity, id, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        if(!filtered) {
            filtered = {[entity]:id};
        }
        let globalFilter = getState().filter.global;
        const fields = ['source', 'curr_units', 'prev_units'];
        return statsService.getSourcesDiscoveryStats(filtered, globalFilter, fields) 
            .then(
                stats => {
                    const data = statsFormatter.formatSources(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_SOURCES_DISCOVERY_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_SOURCES_DISCOVERY_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_SOURCES_DISCOVERY_STATS_FAILURE, error } }
}

function getSourcesTimeseries(entity, id, useCache = false, filtered = null) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        if(!filtered) {
            filtered = {[entity]:id};
        }

        const cache = useCache ? getState().stats.sourcesTimeseriesCache : null, 
        getData = cache ? Promise.resolve(cache) : statsService.getSourcesTimeseries(filtered, globalFilter);

        
        return  getData
            .then(
                stats => {
                    const data = statsFormatter.formatSourcesTimeseries(stats);
                    dispatch(success(data, stats));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_SOURCES_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_SOURCES_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_SOURCES_TIMESERIES_FAILURE, error } }
}

function getSourcesDiscoveryTimeseries(entity, id, useCache = false, filtered = null) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        if(!filtered) {
            filtered = {[entity]:id};
        }

        const cache = useCache ? getState().stats.sourcesDiscoveryTimeseriesCache : null, 
        getData = cache ? Promise.resolve(cache) : statsService.getSourcesDiscoveryTimeseries(filtered, globalFilter);

        
        return  getData
            .then(
                stats => {
                    const data = statsFormatter.formatSourcesTimeseries(stats);
                    dispatch(success(data, stats));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_SOURCES_DISCOVERY_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_SOURCES_DISCOVERY_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_SOURCES_DISCOVERY_TIMESERIES_FAILURE, error } }
}


function getMultiMetadata(entities) {
    const allTypes = ['imprints', 'artists', 'products', 'projects', 'tracks', 'isrcs', 'upcs'];
    let metadataCalls = [];
    for(let type of allTypes) {
        const ids = uniq(map(entities.filter(entity=>entity.entity_type == type), 'entity_id'));
        let metadataCall = Promise.resolve([]);
        if(Array.isArray(ids) && ids.length) {
            switch(type) {
                case 'imprints':
                    metadataCall = imprintsService.getMetadata(ids).then(({data})=>imprintsFormatter.formatMetadataFlat(data));
                    break;
                case 'artists':
                    metadataCall = artistsService.getMetadata(ids, false).then(({data})=>artistsFormatter.formatMetadataFlat(data));
                    break;
                case 'products':
                    metadataCall = productsService.getMetadataByIDs(ids).then(({data})=>productsFormatter.formatMetadataFlat(data));
                    break;
                case 'upcs':
                    metadataCall = productsService.getMetadata(ids).then(({products})=>productsFormatter.formatMetadataBarcodesFlat(products));
                    break;                    
                case 'projects':
                    metadataCall = projectsService.getMetadata(ids).then(({data})=>projectsFormatter.formatMetadataFlat(data));
                    break;                    
                case 'tracks':
                    metadataCall = tracksService.getMetadata(ids).then(({data})=>tracksFormatter.formatMetadataFlat(data));
                    break;
                case 'isrcs':
                    metadataCall = tracksService.getMetadataByISRCS(ids).then(({data})=>tracksFormatter.formatMetadataFlat(data));
                    break;
                    
            }    
        }
        metadataCalls.push(metadataCall);
    }
    return Promise.all(metadataCalls).then(statsFormatter.formatMultiMetadata)
}

function getMultiEntitiesStreams(filtered){
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        statsService.getMultiEntitiesStats(filtered, globalFilter)
            .then(
                entities => {
                    //const metadata = getState().imprints.metadata;
                    //getMultiMetadata(entities)
                    //.then(metadata => {
                        entities = statsFormatter.formatMultiEntitiesStreams(entities);
                        dispatch(success(entities));
                    //});
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_MULTI_ENTITIES_STREAMS_REQUEST } }
    function success(entities) { return { type: statsConstants.GET_MULTI_ENTITIES_STREAMS_SUCCESS, entities } }
    function failure(error) { return { type: statsConstants.GET_MULTI_ENTITIES_STREAMS_FAILURE, error } }
}

function getMultiEntitiesMetadata(entities){
    return ( dispatch, getState ) => {
        dispatch(request());
        let entitiesFlatArray = [];
        for(let entity of Object.keys(entities)) {
            let filterKey = entity;
            if(filterKey.startsWith('exclude_'))
                filterKey = filterKey.replace('exclude_', '');
            for(let id of entities[entity]) {
                entitiesFlatArray.push({
                    entity_id: id,
                    entity_type: filterKey
                })
            }
        }
        const globalFilter = getState().filter.global;
        const currentUser = getState().user.user;
        
        return getMultiMetadata(entitiesFlatArray)
            .then(metadata => {
                const minDate = statsFormatter.extractMinDate(metadata, currentUser);
                dispatch(success(metadata, minDate));
                return metadata;
            },
            error => {
                console.log(error);
                dispatch(failure('error'))
            }
        );
    };

    function request() { return { type: statsConstants.GET_MULTI_ENTITIES_METADATA_REQUEST } }
    function success(entities, minDate) { return { type: statsConstants.GET_MULTI_ENTITIES_METADATA_SUCCESS, entities, minDate } }
    function failure(error) { return { type: statsConstants.GET_MULTI_ENTITIES_METADATA_FAILURE, error } }
}

function resetMinDate() {
    return ( dispatch, getState ) => {
        const currentUser = getState().user.user;
        dispatch(reset(currentUser.first_data_date));
    };

    function reset(firstDate) { return { type: statsConstants.RESET_RELEASE_MIN_DATE, firstDate } }
}

function getLocationStats(territory, filtered, sorted, limit, mode) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        if(territory !=='world')
            filtered.push({id: 'territories', value: territory});
        
        let fields = ['city', 'streams_total'];
        if(mode=='expanded'){
            fields.push('listeners');
        }
        if(territory == 'world')
            fields.push('territory');   
   
        
        if(!sorted) {
            sorted = {field: 'streams_total', dir: 'desc'};
        }
        
        const params = {filtered, sorted, limit: 10};
        return statsService.getLocationStats(params, globalFilter, fields, false) 
            .then(
                stats => {
                    const data = statsFormatter.formatLocationStats(stats);
                    dispatch(success(territory, data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_LOCATION_STATS_REQUEST } }
    function success(territory, stats) { return { type: statsConstants.GET_LOCATION_STATS_SUCCESS, territory, stats } }
    function failure(error) { return { type: statsConstants.GET_LOCATION_STATS_FAILURE, error } }
}

function getTopLocationsStats(territories, filtered, sorted, limit) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        let fields = ['territory','city', 'streams_total'];
        filtered.push({id: 'territories', value: territories});
        
        if(!sorted) {
            sorted = {field: 'streams_total', dir: 'desc'};
        }
        
        const params = {filtered, sorted, limit};
        return statsService.getLocationStats(params, globalFilter, fields, true) 
            .then(
                stats => {
                    const data = statsFormatter.formatTopLocationStats(stats);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_LOCATIONS_STATS_REQUEST } }
    function success(stats) { return { type: statsConstants.GET_TOP_LOCATIONS_STATS_SUCCESS, stats } }
    function failure(error) { return { type: statsConstants.GET_TOP_LOCATIONS_STATS_FAILURE, error } }
}