import { spotifyMonetizationConstants } from '../constants/spotify_monetization';

export function spotifyMonetization(state = {data: {}, totals: {}, timeseries: {}, dates: {}}, action) {
	switch (action.type) {
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_REQUEST:
			return {
				...state,
				loading: true,
				data: {
					...state.data,
					[action.platform]: []
				}
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					[action.platform]: action.data
				}
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
	
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST:
			return {
				...state,
				loadingTotals: true
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS:
			return {
				...state,
				loadingTotals: false,
				totals: {
					...state.totals,
					[action.platform]: action.data
				}

			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE:
			return {
				...state,
				loadingTotals: false,
				error: action.error
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_REQUEST:
			return {
				...state,
				loadingTimeseries: true,
				timeseries: {
					...state.timeseries,
					[action.platform]: null
				}

			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_SUCCESS:
			return {
				...state,
				loadingTimeseries: false,
				timeseries: {
					...state.timeseries,
					[action.platform]: action.data
				}

			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_FAILURE:
			return {
				...state,
				loadingTimeseries: false,
				error: action.error
			};
			
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_REQUEST:
			return {
				...state,
				loadingDates: true,
				dates: {
					...state.dates,
					[action.platform]: []
				}

			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_SUCCESS:
			return {
				...state,
				loadingDates: false,
				dates: {
					...state.dates,
					[action.platform]: action.dates
				}

			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_FAILURE:
			return {
				...state,
				loadingDates: false,
				error: action.error
			};

		default:
			return state
	}
} 