import moment from 'moment';
import store from './store';
import { find } from 'lodash';
import vendors from '../components/widgets/filter/enums/vendors';
import sources from '../components/widgets/filter/enums/sources.json';
import contentTypes from '../components/widgets/filter/enums/content_types.json';
import audioFormats from '../components/widgets/filter/enums/audio_formats.json';

const allValuesSelected = {
  'sources': sources.length,
  'contentType': contentTypes.length, 
  'audioFormats': audioFormats.length
};

export default function BuildQS(params){

    if(params.dateStart && params.dateEnd) {
        let dateStart = moment(params.dateStart),
            dateEnd = moment(params.dateEnd),
            currentDuration = dateEnd.diff(moment(dateStart), 'days'),
            duration = moment.duration(currentDuration+1, 'days').asDays();
        
        params.prevDateStart = params.prevDateStart || dateStart.subtract(duration, 'days').format('YYYY-MM-DD');
        params.prevDateEnd = params.prevDateEnd || dateEnd.subtract(duration, 'days').format('YYYY-MM-DD');
    }

    const keyMap = {
        'dateStart': 'curr_start',
        'dateEnd': 'curr_end',
        'prevDateStart': 'prev_start',
        'prevDateEnd': 'prev_end',
        'releaseStart': 'release_start',
        'releaseEnd': 'release_end',        
        'territories': 'territories',
        'vendors': 'vendors',
        'sources': 'sources',
        'genres': 'genres',
        'limit': 'row_limit',
        'range': 'date_grouping',
        'contentType': 'content_type',
        'active': 'active',
        'filtered': 'filtered',
        'filteredBy': 'filteredBy',
        'imprints': 'imprints',
        'artists': 'track_artists',
        'products': 'products',
        'projects': 'projects',
        'tracks': 'tracks',
        'audioFormats': 'audio_format',
        'forcePrev': 'force_prev'
    },
    user = store.get('user'),
    userKeyMap = {
        'id': 'user_id',
        'primary_silo_code': 'silo_code',
        'client_admin': 'client_admin'
    };

    let primarySilo = user.data_silo_codes;
    if(primarySilo.includes(','))
        primarySilo = primarySilo.split(',')[0];
    user.primary_silo_code = primarySilo;

    let result = {};
    for(let key of Object.keys(params)){
        
        let value = params[key];
        
        if(key=='vendors') {
            let vendorIds = [];
            for(let vendorName of value) {
                if(Number.isInteger(vendorName))
                    vendorIds.push(vendorName);
                else {
                    const vendorData = find(vendors, {value: vendorName});
                    if(vendorData)
                        vendorIds.push(vendorData.vend_id);
                }
            }
            value = vendorIds;
        }
        
        
        if(value instanceof Array){
          if(allValuesSelected[key] !== undefined && value.length === allValuesSelected[key]) {
            // console.log(`${key} all values selected`);
            value = null;
          } else {
            value = value.join(',');
          }
            
        }
        if(value)
            result[keyMap[key]] = value;
    }
    if(user) {
        for(let key in userKeyMap) {
            result[userKeyMap[key]] = user[key]
        }
    }
    /*
    if(params.filtered) {
        const globalFilter = store.get('globalFilter');
        const filteredEntities = globalFilter.filter(entity => entity.fav_hide == params.filtered);
        const paramsPrefix = params.filtered == 'hidden' ? 'exclude_' : '';
        let newParams = {imprints: '', artists: '', products: '', tracks: ''};
        for (let entity of filteredEntities) {
            if(newParams[entity.entity_type])
                newParams[entity.entity_type] += ',';
            newParams[entity.entity_type] += entity.entity_id;
        }
        
        for(let newParamKey of Object.keys(newParams)) {
            if(!newParams[newParamKey])
                continue;
            
            const paramKey = paramsPrefix + newParamKey;
            if(result[paramKey])
                continue; // don't overwrite explicit ID filters
            else
                result[paramKey] = newParams[newParamKey];
        }
    }
    */

    cleanResult(result);
    return result;
}

function cleanResult(result){
  return Object.keys(result).forEach(key => key === 'undefined' && delete result[key])
}
