import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class MissingReleasesWarning extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render (){
		const {user: {primary_silo_code}} = this.props.user;
		const showWarning = primary_silo_code == 'exc';
		if(showWarning){
			return <div className="missing-data-warning-holder">
				<div className="missing-data-warning">
					<strong>Note: </strong>
					New releases since Feb 1st 2025 are affected by the move to Redeye distribution. We’re aware these titles are missing and are working to resolve this
				</div>
			</div>
		}
		return null;            
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	} 
}

export default connect(mapStateToProps)(MissingReleasesWarning)