import React from 'react';
import { connect } from 'react-redux';
import { find, capitalize, cloneDeep, get, merge } from 'lodash';
import moment from 'moment';
import { mailingListsActions } from '../../../data/actions/mailing_lists';
import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters';
import { mailingListsFormatter } from '../../../data/formatters/mailing_lists';
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import Calendar from './Calendar';
import Datepicker from '../../common/Datepicker';
import RecipientsInput from './RecipientsInput';
import { Link } from 'react-router-dom';

const defaultMailingList = { date_range: 'one_week', enabled: true, share_type: 'share', shared_tabs: []};
class MailingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                mailingList: null,
                recipients: [],
                initialized: false
        }
    }
    
    componentDidMount() {
        const { entityFilterId, dispatch } = this.props;
        dispatch(mailingListsActions.getMailingListByEntityFilter(entityFilterId))
        .then(mailingList=>{
            if(mailingList) {
                this.setState({mailingList, initialized: true}, ()=>{
                    dispatch(mailingListsActions.getMailingListRecipients(mailingList.id))
                    .then(recipients=>{
                        this.setState({recipients: cloneDeep(recipients)});
                    });                    
                });
            }
            else {
                this.setState({mailingList: {...defaultMailingList, user_filter_id: entityFilterId}, initialized: true});
            }
        })
    }
    
    setValue = (key, value) => {
        this.setState({
            ...this.state,
            mailingList: {
                ...this.state.mailingList,
                [key]: value
            }
        })
    }
    
	setDate = (date) => {
		date = moment(date).format('YYYY-MM-DD');
		return this.setValue('end_date', date);
	}
	
    setRecipients = (recipients) => {
        if(!recipients)
            recipients = [];
        this.setState({recipients});
    }
    
    addRecipient = (email, mailingList) => {
        const { dispatch } = this.props;
        dispatch(mailingListsActions.addRecipient(email, mailingList));
    }

    deleteRecipient = (id) => {
        const { dispatch } = this.props;
        dispatch(mailingListsActions.deleteRecipient(id, mailingList));
    }
    
    saveRecipients = () => {
        const { recipients, mailingList } = this.state;
        const { mailingLists, dispatch } = this.props;
        const originalRecipients = mailingLists.mailingListRecipients[mailingList.id] ? mailingLists.mailingListRecipients[mailingList.id] : [];
        
        let recipientCalls = [];
        
        if(recipients) {
            let recipientsToAdd = recipients.filter(recipient=>!recipient.id);
            for(let recipient of recipientsToAdd)
                recipientCalls.push(dispatch(mailingListsActions.addRecipient(recipient.value, mailingList.id)));
        }
        for(let originalRecipient of originalRecipients) {
            if(!find(recipients, recipient=>recipient.id == originalRecipient.id))
                recipientCalls.push(dispatch(mailingListsActions.deleteRecipient(originalRecipient.id, mailingList.id)));
        }
        
        return recipientCalls.reduce((promiseChain, currentTask) => {
            return promiseChain.then(chainResults =>
                currentTask.then(currentResult =>
                    [ ...chainResults, currentResult ]
                )
            );
        }, Promise.resolve([])).then(arrayOfResults => {
            dispatch(mailingListsActions.getMailingListRecipients(mailingList.id));
        });

    }
    
    setOption = (option) => {
        let options = Object.assign([], this.state.mailingList.shared_tabs);
        if(options.includes(option))
            options = options.filter(op => op != option);
        else
            options.push(option);
        this.setValue('shared_tabs', options);
    }
    
    save = () => {
        const { dispatch, entityFilterId } = this.props;
        const { mailingList } = this.state;
        const saveMailingList = !mailingList.id ? 
            dispatch(mailingListsActions.addMailingList(mailingList)).then(mailingList=>new Promise((resolve) => this.setState({mailingList}, resolve))) 
            : dispatch(mailingListsActions.editMailingList(mailingList, mailingList.id));
        saveMailingList.then(this.saveRecipients).then(()=>dispatch(mailingListsActions.getMailingListByEntityFilter(entityFilterId, false)));
    }
    
    setCalendar = (calendar) => {
        let value = mailingListsFormatter.formatCalendar(calendar);
        this.setState({
            ...this.state,
            mailingList: {
                ...this.state.mailingList,
                start_date: calendar.date,
                schedule_type: calendar.period,
                schedule_value: value
            }            
        })

    }
    
    
    
    renderDateRange = (mailingList) => {
        const options = [{label: '1 week' , value: 'one_week'}, {label: '4 weeks', value: 'four_weeks'}, {label: '3 months', value: 'three_months'}, {label: '6 months', value: 'six_months'}, {label:'Release to date', value:'release_to_date'}];
        //const options = rangeTypes.map(range=>({value: range, label: capitalize(range.replace(/_/g, ' '))}));
        const value = mailingList ? find(options, option=>option.value == mailingList.date_range) : null;
        return <ReactSelect id="mailing_list_date_range" options={options} value={value} onChange={({value})=>this.setValue('date_range', value)} />
    }
    
    renderPages = (mailingList) => {
        if(mailingList.share_type != 'download')
            return null;
        const selected = mailingList.shared_tabs;
        const options = [{label: 'Overview' , value: 'overview'}, {label: 'Audience', value: 'audience'}, {label: 'Social Music', value: 'social_music'}, {label: 'Playlists', value: 'playlists'}, {label:'Milestones', value:'milestones'}];
        const renderPage = (option) => {
            const { label, value } = option;
            const optionSelected = selected.length == 0 || selected.includes(value);
            return <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
               <span className="inline-checkbox-with-label"><input type="checkbox" id={`page_${value}`} checked={optionSelected} onChange={()=>this.setOption(value)} class="input" />
               <label htmlFor={`page_${value}`} className="checkbox-label checkbox-switcher"> </label> {label}</span>
           </div>
            
        }
        return <React.Fragment>
            <div className="mailing-list-item">
                <label>Available Pages:</label>
                {renderPage(options[0])}
                {renderPage(options[1])}
            </div>   
            <div className="mailing-list-item">
                {renderPage(options[2])}
                {renderPage(options[3])}
                {renderPage(options[4])}
            </div>   

        </React.Fragment>
        
    }

    renderGlobalFilters = (mailingList) => {
        const filters = this.props.globalFilter.data;
        const options = [{value:null, label:'(none)'}].concat(filters.map(filter=>({value: filter.id, label: filter.filter_name})));
        const value = mailingList ? find(options, option=>option.value == mailingList.filter_id) : null;
        return <ReactSelect id="mailing_list_global_filter" options={options} value={value} onChange={({value})=>this.setValue('filter_id', value)} />
    }
    
    render() {
        let { initialized, mailingList } = this.state;
        
        mailingList = merge({}, defaultMailingList,  mailingList);
        
		const endDate = get(mailingList, 'end_date');
        return <Box title="Filter Notification Settings" className="mailing-list-holder" spinnerEnabled={!initialized}>
            {initialized && <React.Fragment> 
            <div>
                <div className="mailing-list-options">
                    <div className="mailing-list-item mailing-list-enabled-holder">
                        <label>Enabled:</label>
                        <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
                           <input type="checkbox" id={`filter_enabled`} checked={mailingList.enabled} onChange={()=>this.setValue('enabled', !mailingList.enabled)} className="input" />
                           <label htmlFor={`filter_enabled`} className="checkbox-label checkbox-switcher"></label>
                       </div>
                    </div>   
                    <div className="mailing-list-item mailing-list-item--dates">
                        <div className="mailing-list-date-item">
                            <label htmlFor="mailing_list_calendar" className="repeats-on-holder"><span className="start-date-label">Start Date:</span> <span className="repeats-on">Repeats On:</span></label>
                            <Calendar mailingList={mailingList} onChange={this.setCalendar} />
                        </div>
                        <div className="mailing-list-date-item">
                            <label htmlFor="mailing_list_calendar_end" className="">End Date: 
                                <div className="mailing-list-calendar-holder inline-datepicker">
                                    <Datepicker datepickerPosition="right" className="calendar" placeholder="(not set)" date={endDate} dateChange={this.setDate} readonly={false} />
                                </div>						
                            </label>
                        </div>
                    </div>
                    <div className="mailing-list-item mailing-list-item--filter static-select-menu">
                        <div className="mailing-list-filter-item">
                            <label htmlFor="mailing_list_date_range">Date Range: {this.renderDateRange(mailingList)}</label>
                        </div>
                        <div className="mailing-list-filter-item">
                            <label htmlFor="mailing_list_global_filter">Global Filter: {this.renderGlobalFilters(mailingList)}</label>
                        </div>
                    </div>             
                </div>
                <div className="mailing-list-options">
                    <div className="mailing-list-item">
                        <label>Share Type:</label>
                        <div className="filter-radio-holder">
                          <input type="radio" className="visually-hidden" id="share_type_share" checked={mailingList.share_type == 'share'} onClick={()=>this.setValue('share_type', 'share')} />
                          <label htmlFor="share_type_share" className="small-text">Shared Page</label>
                        </div>
                        <div className="filter-radio-holder">
                          <input type="radio" className="visually-hidden" id="share_type_download" checked={mailingList.share_type == 'download'} onClick={()=>this.setValue('share_type', 'download')} />
                          <label htmlFor="share_type_download" className="small-text">Download XLSX</label>
                        </div>
                    </div>    
                    {this.renderPages(mailingList)}              
                </div>
                
                <div className="recipient-holder">
                    <label htmlFor="mailing_list_recipients">Recipients: <RecipientsInput value={this.state.recipients} setValue={this.setRecipients}/></label>
                </div>
            </div>
            <div className="mailing-list-actions">
                <button className="default-btn default-btn--small mt-20" onClick={this.save}>Save Notifications</button>
                {mailingList.id && <Link className="default-btn default-btn--small mt-20" to={`/user/mailing-lists/recipients/${mailingList.id}`}>Manage Recipients</Link>}
            </div>
            </React.Fragment>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        mailingLists: state.mailingLists,
        globalFilter: state.filter
    } 
}


export default connect(mapStateToProps)(MailingList);